import { useMemo } from 'react';
import useGetVHData from '../useGetVHData';

/**
 * @type {(vhId: number[], skip?: boolean) => {vhIdsWithEvaluationsOrObjectives: number[]; loading: boolean; error: any;}}
 */
const useGetVHsWithEvaluationsOrObjectives = (vhIds, skip) => {
  const { data, loading, error } = useGetVHData(
    `virtual-humans?idOnly=true&hasEvaluationsOrObjectives=true&ids=${vhIds.join(
      ','
    )}`,
    {
      skip
    }
  );

  const vhIdsWithEvaluationsOrObjectives = useMemo(() => {
    if (typeof data?.data?.map !== 'function') return [];
    return data?.data?.map((/** @type {{id: number}} */ vh) => vh.id) ?? [];
  }, [data]);

  return { vhIdsWithEvaluationsOrObjectives, loading, error };
};

export default useGetVHsWithEvaluationsOrObjectives;
