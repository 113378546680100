import * as Styled from './TypeBadge.styled';
import { memo } from 'react';

const TypeBadge = memo(({ type }) => {
  if (type === 'freeform_v3') {
    return <Styled.BadgePink>Conversational</Styled.BadgePink>;
  }
  if (type === 'medical') {
    return <Styled.BadgeBlue>Medical</Styled.BadgeBlue>;
  }
  if (type === 'branching') {
    return <Styled.BadgeTeal>Guided</Styled.BadgeTeal>;
  }
  return <Styled.BadgePink>Freeform</Styled.BadgePink>;
});

export default TypeBadge;
