import { useMutation } from '@apollo/client';
import SimEmbedCode from '@core/components/Simulation/SimEmbedCode/SimEmbedCode';
import {
  SAVE_REGISTER_SIM,
  SEND_EMAIL_SHARE_SIM,
} from '@core/components/Simulation/SimShareForm/SimShareForm.query';
import {
  Description,
  FieldContainerStyled,
  RegisterBox,
  ShareModal,
  SwitchLabel,
} from '@core/components/Simulation/SimShareForm/SimShareForm.styled';
import Label from '@core/components/form/Label';
import useAutosave from '@core/helpers/useAutosave';
import { HelpTooltip, CreateSelect, Button } from '@virtidev/toolbox';
import { FC, useState, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Simulation } from '@core/models/simulation.types';
import { useFlashMessage } from '@core/components/FlashMessage';
import { difference } from 'lodash';
import tracker from '@core/helpers/tracker';
import { Userpilot } from 'userpilot';
import useUser from '@core/helpers/useUser';

/**
 * @type {FC<{
 *    simulation: Simulation,
 *    onClose: () => void,
 *    show: boolean,
 * }>}
 */
export const SimShareForm = ({ simulation, onClose, show }) => {
  const { addFlashMessage } = useFlashMessage();
  const { Organisation } = useUser();
  const formMethods = useForm({
    defaultValues: simulation,
  });
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState(/** @type {string[]} */ ([]));
  const emailsRef = useRef(emails);
  emailsRef.current = emails;

  const { reset } = formMethods;

  const [saveRegisterSim] = useMutation(SAVE_REGISTER_SIM, {
    onError: () => reset(simulation),
  });

  const [sendEmailShareSim] = useMutation(SEND_EMAIL_SHARE_SIM);

  const saveRegister = useCallback(
    (input) => {
      saveRegisterSim({
        variables: {
          input: { ID: simulation.ID, ShowRegister: input.ShowRegister },
        },
      });
    },
    [simulation, saveRegisterSim]
  );

  const sendInvites = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));

    if (!emailsRef.current.length) {
      return;
    }
    const sendingEmails = [...emailsRef.current];
    setEmails([]);

    const { data } = await sendEmailShareSim({
      variables: { simId: simulation.ID, emails: sendingEmails },
    });

    tracker.track('share_invite', {
      type: 'simulation',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      emailCount: sendingEmails.length,
    });

    const sent = data?.emailSimulationShareLink || [];
    const unsent = difference(sendingEmails, sent);

    if (sent.length) {
      addFlashMessage(`Sent email successfully to ${sent.length} users`);
    }

    if (unsent.length) {
      setEmails(unsent);
      addFlashMessage(
        `Failed to send emails to: "${unsent.join('", "')}"`,
        'error'
      );
    } else {
      onClose();
    }
  }, [
    sendEmailShareSim,
    simulation.ID,
    Organisation,
    addFlashMessage,
    onClose,
  ]);

  useAutosave(formMethods, saveRegister);

  return (
    <ShareModal
      show={show}
      title="Share your Simulation"
      onHide={onClose}
      render={() => (
        <>
          <Description>
            Share what you&apos;ve created and let others join in! Anyone with a
            share email or link can view your Simulation.
          </Description>

          <FormProvider {...formMethods}>
            <RegisterBox name="ShowRegister">
              <SwitchLabel>
                <Label as="div" horizontal>
                  Show create account banner
                </Label>
                <Description>
                  If enabled, users can join your organization
                </Description>
              </SwitchLabel>
            </RegisterBox>
          </FormProvider>

          <FieldContainerStyled>
            <Label horizontal>Share</Label>
            <SimEmbedCode
              token={simulation.ShareToken}
              id={simulation.ID}
              title={simulation.Title}
            />
          </FieldContainerStyled>

          <FieldContainerStyled>
            <Label horizontal>
              <span style={{ flex: 1 }}>Email invite</span>
              <HelpTooltip>
                Users with an email invite can access this Simulation without
                creating an account.
              </HelpTooltip>
            </Label>
            <CreateSelect
              value={emails}
              onChange={setEmails}
              onInputChange={setEmailInput}
              isEmailList
            />
          </FieldContainerStyled>
        </>
      )}
      footerRender={() => (
        <>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={emails.length === 0 && emailInput.length === 0}
            onClick={sendInvites}
          >
            Send
          </Button>
        </>
      )}
    />
  );
};

export default SimShareForm;
