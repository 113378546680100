import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { CX_ORGANISATION_ANALYTICS } from '../../../../../queries/CXQueries';
import _ from 'lodash';
import {
  StyledWrapper,
  StyledHelpTooltip,
  StyledTitleAndTooltip,
  StyledTwoBlockContainer,
} from './CXOrganisationAnalytics.styled';
import {
  S_tr,
  S_table,
  S_thLeft,
  S_td,
  S_tbody,
} from '../../../../../styled-components/StyledTable';
import { StyledDataBlock } from '../../../../../styled-components/StyledDataBlocks';
import StyledLabel from '../../../../../styled-components/StyledLabel';
import BarChartActiveUsersHighstock from '../../../../../components/charts/BarChartActiveUsersHighstock';
import PageLoadError from '../../../../../components/PageLoadError';
import LoadingPageIndicator from '../../../../../components/LoadingPageIndicator';

const CXOrganisationAnalytics = ({ orgID }) => {
  const { loading, error, data } = useQuery(CX_ORGANISATION_ANALYTICS, {
    variables: { organisationID: orgID },
  });

  if (error) return <PageLoadError graphQLErrorObj={error} />;
  if (loading) return <LoadingPageIndicator />;

  const dataObj = data?.cxOrganisationAnalytics;
  if (!dataObj) return <PageLoadError message="No data returned" />;

  const retention = JSON.parse(_.get(dataObj, 'Retention', '{}'));
  const retentionKeys = Object.keys(retention);
  const retentionArray = retentionKeys.map((key) => {
    const roundedRate = Math.round(retention[key]);
    const day = key.slice(0, -3) + ' Day';
    const keyValuePair = { rate: roundedRate, day };
    return keyValuePair;
  });

  return (
    <>
      <StyledTwoBlockContainer>
        <StyledWrapper>
          <StyledDataBlock>
            <StyledLabel>Retention</StyledLabel>
            <S_table>
              <S_tbody>
                {retentionArray.map((item) => {
                  return (
                    <S_tr key={item.day}>
                      <S_thLeft>{item.day}</S_thLeft>
                      <S_td>{item.rate}%</S_td>
                    </S_tr>
                  );
                })}
              </S_tbody>
            </S_table>
          </StyledDataBlock>
        </StyledWrapper>
        <StyledWrapper>
          <StyledDataBlock>
            <StyledLabel>Activity</StyledLabel>
            <S_table>
              <S_tbody>
                <S_tr>
                  <S_thLeft>
                    <StyledTitleAndTooltip>
                      Accounts Created
                      <StyledHelpTooltip
                        text="Total accounts excluding Organisation Administrators and Virti Administrators"
                        subtle={true}
                      />
                    </StyledTitleAndTooltip>
                  </S_thLeft>
                  <S_td>{dataObj.TotalUsers}</S_td>
                </S_tr>
                <S_tr>
                  <S_thLeft>
                    <StyledTitleAndTooltip>
                      Accounts Activated
                      <StyledHelpTooltip
                        text="Total organisation accounts excluding Organisation Administrators and Virti Administrators"
                        subtle={true}
                      />
                    </StyledTitleAndTooltip>
                  </S_thLeft>
                  <S_td>{dataObj.ActiveUsers}</S_td>
                </S_tr>
                <S_tr>
                  <S_thLeft>
                    <StyledTitleAndTooltip>
                      Last Month Unique Users
                      <StyledHelpTooltip
                        text="Average number of unique users with at least one analytics submission."
                        subtle={true}
                      />
                    </StyledTitleAndTooltip>
                  </S_thLeft>
                  <S_td>{dataObj.MAU}</S_td>
                </S_tr>
                <S_tr>
                  <S_thLeft>
                    <StyledTitleAndTooltip>
                      Last Month Unique Admins
                      <StyledHelpTooltip
                        text="Average number of unique admins with at least one analytics submission."
                        subtle={true}
                      />
                    </StyledTitleAndTooltip>
                  </S_thLeft>
                  <S_td>{dataObj.MAA}</S_td>
                </S_tr>
              </S_tbody>
            </S_table>
          </StyledDataBlock>
        </StyledWrapper>
      </StyledTwoBlockContainer>

      <StyledTwoBlockContainer>
        <StyledWrapper>
          <BarChartActiveUsersHighstock
            AU={dataObj.DAU}
            title="Daily Active Users"
            userType="Users"
          />
        </StyledWrapper>
        <StyledWrapper>
          <BarChartActiveUsersHighstock
            AU={dataObj.DAA}
            title="Daily Active Admins"
            userType="Admins"
          />
        </StyledWrapper>
      </StyledTwoBlockContainer>

      <StyledTwoBlockContainer>
        {dataObj.MAUMonthly && (
          <StyledWrapper>
            <BarChartActiveUsersHighstock
              AU={dataObj.MAUMonthly}
              title="Monthly Active Users"
              userType="Users"
            />
          </StyledWrapper>
        )}
        {dataObj.MAAMonthly && (
          <StyledWrapper>
            <BarChartActiveUsersHighstock
              AU={dataObj.MAAMonthly}
              title="Monthly Active Admins"
              userType="Admins"
            />
          </StyledWrapper>
        )}
      </StyledTwoBlockContainer>
    </>
  );
};

CXOrganisationAnalytics.propTypes = {
  orgID: PropTypes.string.isRequired,
};

export default CXOrganisationAnalytics;
