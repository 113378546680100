import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VHTemplateNodePropType } from '../VHCreationWizard.propTypes';
import { Button, TextInput } from '@virtidev/toolbox';
import VHLocaleSelectionWithDefaultVoice from '@base/components/VirtualHumanCreationWizard/VHCreationSteps/VHLocaleSelectionWithDefaultVoice/VHLocaleSelectionWithDefaultVoice';
import * as Styled from './VHNameLocaleStep.styled';

const VHNameLocaleStep = (props) => {
  const [selectedVoiceData, setSelectedVoiceData] = useState(null);
  const [selectedLocaleData, setSelectedLocaleData] = useState(null);
  const [nameValue, setNameValue] = useState('');

  const canSave =
    selectedVoiceData && selectedLocaleData && nameValue && !props.loading;

  const saveDetails = () => {
    if (!canSave) return;
    props.confirmAction({
      Title: nameValue,
      Locale: selectedLocaleData,
      Voice: selectedVoiceData,
    });
  };

  const handleVoiceChange = useCallback(
    (newItemData) => {
      setSelectedVoiceData(newItemData);
    },
    [setSelectedVoiceData]
  );

  const handleLocaleChange = useCallback(
    (newLocaleData) => {
      setSelectedLocaleData(newLocaleData);
    },
    [setSelectedLocaleData]
  );

  const vhTemplate = props.creationDetails?.Template?.VirtualHuman;
  const gender = props.creationDetails?.Avatar?.Gender;

  return (
    <Styled.Fields>
      <TextInput
        type="text"
        onChange={(e) => setNameValue(e.target.value)}
        label="Name"
        value={nameValue}
      />
      <VHLocaleSelectionWithDefaultVoice
        onLocaleChange={handleLocaleChange}
        onVoiceChange={handleVoiceChange}
        initialVoiceData={null}
        initialLocaleCode={vhTemplate?.Locale?.BCP47Code || 'en-US'}
        vhType={vhTemplate?.Type}
        gender={gender}
        label="Language"
      />
      <Styled.Actions>
        <Button type="button" color="secondary" onClick={props.cancelAction}>
          Cancel
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={saveDetails}
          disabled={!canSave}
        >
          Create
        </Button>
      </Styled.Actions>
    </Styled.Fields>
  );
};

export default VHNameLocaleStep;

VHNameLocaleStep.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  creationDetails: PropTypes.object,
  loading: PropTypes.bool,
};
