import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  S_tr,
  S_td,
  S_table,
  S_thead,
  S_tbody,
  S_th,
} from '../styled-components/StyledTable';
import { GetFormattedTimeLong } from '../utility/TimeFormatting';
import StyledLink from '../styled-components/StyledLink';
import StyledScorePercentage from '../styled-components/StyledScorePercentage';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HoveringTooltip } from './HelpTooltip';
import { Button, Datetime } from '@virtidev/toolbox';
import { GetAccessToken } from '../utility/LoginUtility';
import getConfig from '../lib/config';
import { saveAs } from 'file-saver';
import useGetVHsThatShouldShowScoreAndSessionReport from '@base/utility/VHHooks/API/useGetVHsThatShouldShowScoreAndSessionReport';
import { uniq } from 'lodash';

const StyledTable = styled(S_table)`
  /* transition: 0.5s all; */
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  display: ${({ visible }) => (visible ? 'table' : 'none')};
`;

const StyledSuccessWrapper = styled.div`
  position: relative;
  margin: auto;
  display: inline-block;
  &:hover .hovering-tooltip {
    display: block;
    width: auto;
    top: -0.5rem;
    transform: translateX(-44%) translateY(-100%);
  }
`;

const VirtualHumanScoresTable = ({
  userId,
  scores,
  showPosition,
  nameColumn,
  useUserAnalyticsLink,
}) => {
  let someScoresAreMedical = scores.some(
    (score) => score.VirtualHuman.Type === 'medical'
  );
  const token = GetAccessToken();
  const [downloading, setDownloading] = useState(false);

  const vhIds = useMemo(() => {
    return uniq(scores.map((score) => score.VirtualHuman.ExternalID));
  }, [scores]);

  const { vhIdsThatShouldShowScoreAndSessionReport, loading, error } =
    useGetVHsThatShouldShowScoreAndSessionReport(
      vhIds,
      scores?.length === 0 || vhIds?.length === 0
    );

  const downloadSessions = useCallback(() => {
    const url = `${getConfig(
      'REACT_APP_REMOTE_URI'
    )}export/userVirtualHumanScores/${userId}`;

    setDownloading(true);
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        const data = await res.blob();
        const filename = decodeURI(res.headers.get('Filename') || 'export.csv');

        saveAs(data, filename, {
          autoBom: true,
        });
        setDownloading(false);
      })
      .catch((err) => {
        console.error(err);
        setDownloading(false);
      });
  }, [token, userId]);

  return (
    <>
      <Button
        color="primary"
        icon="download"
        onClick={downloadSessions}
        loading={downloading}
        title="pd-score-report"
      >
        Export All Sessions
      </Button>
      <StyledTable visible>
        <S_thead>
          <S_tr>
            {showPosition && <S_th>Position</S_th>}
            {nameColumn === 'user' && <S_th>User</S_th>}
            {nameColumn === 'virtual-human' && <S_th>Virtual Human</S_th>}
            <S_th>Date</S_th>
            <S_th>Time Spent</S_th>
            <S_th>Conversation score</S_th>
            {someScoresAreMedical && (
              <>
                <S_th>Correct Diagnosis</S_th>
              </>
            )}
            <S_th>Chat Logs</S_th>
          </S_tr>
        </S_thead>
        <S_tbody>
          {scores && scores.length === 0 && (
            <S_tr>
              <S_td colSpan="10">No scores found.</S_td>
            </S_tr>
          )}
          {scores &&
            scores.map((score, index) => {
              const analyticsLink = useUserAnalyticsLink
                ? `/users/${score.Member.ID}/analytics/virtual-humans/${score.VirtualHuman.ID}`
                : `/virtual-humans/${score.VirtualHuman.ID}/analytics/user/${score.Member.ID}`;
              const hasObjectivesOrEvaluations =
                vhIdsThatShouldShowScoreAndSessionReport.includes(
                  score.VirtualHuman.ExternalID
                );
              return (
                <S_tr key={score.ID}>
                  {showPosition && <S_td>{index + 1}</S_td>}
                  {nameColumn && (
                    <S_td>
                      <StyledLink to={analyticsLink}>
                        {nameColumn === 'user'
                          ? score.Member.Name
                          : score.VirtualHuman.Title}
                      </StyledLink>
                    </S_td>
                  )}
                  <S_td title={score.Created}>
                    <Datetime datetime={score.Created} long noTimeDisplay />
                  </S_td>
                  {/* workaround for old data that was in seconds */}
                  <S_td>
                    {GetFormattedTimeLong(
                      score.TimeSpent < 1000
                        ? score.TimeSpent * 1000
                        : score.TimeSpent
                    )}
                  </S_td>
                  <S_td>
                    <StyledScorePercentage
                      align="center"
                      score={score.PDConversationScore}
                      showNA={
                        score.PDConversationScore === -1 ||
                        !score.Finished ||
                        !hasObjectivesOrEvaluations
                      }
                      NA={
                        score.PDConversationScore === -1 ||
                        !hasObjectivesOrEvaluations
                          ? 'N/A'
                          : '-'
                      }
                    >
                      {score.PDConversationScore}
                    </StyledScorePercentage>
                  </S_td>
                  {someScoresAreMedical && (
                    <S_td>
                      <StyledSuccessWrapper>
                        {score.Diagnosis && (
                          <HoveringTooltip className="hovering-tooltip">
                            {score.Diagnosis}
                          </HoveringTooltip>
                        )}
                        {score.PDDiagnosisScore === -1 && 'N/A'}
                        {score.PDDiagnosisScore !== -1 &&
                          !score.Finished &&
                          '-'}
                        {score.Finished && score.PDDiagnosisScore === 100 && (
                          <FontAwesomeIcon
                            style={{ color: 'var(--positive-color)' }}
                            icon="check"
                          />
                        )}
                        {score.Finished && score.PDDiagnosisScore === 0 && (
                          <FontAwesomeIcon
                            style={{
                              color: 'var(--negative-color)',
                              opacity: score.Diagnosis ? '1' : '0.4',
                            }}
                            icon="times"
                          />
                        )}
                      </StyledSuccessWrapper>
                    </S_td>
                  )}
                  <S_td>
                    {score.PDAssessmentID && (
                      <StyledLink
                        to={`/virtual-humans/${score.VirtualHuman.ID}/builder/patient/${score.VirtualHuman.ExternalID}/chat-logs/${score.PDAssessmentID}`}
                      >
                        Chat Log
                      </StyledLink>
                    )}
                  </S_td>
                </S_tr>
              );
            })}
        </S_tbody>
      </StyledTable>
    </>
  );
};

VirtualHumanScoresTable.defaultProps = {
  showPosition: false,
  useUserAnalyticsLink: false,
};

VirtualHumanScoresTable.propTypes = {
  scores: PropTypes.array.isRequired,
  showPosition: PropTypes.bool,
  nameColumn: PropTypes.string.isRequired,
  useUserAnalyticsLink: PropTypes.bool.isRequired,
};

export default VirtualHumanScoresTable;
