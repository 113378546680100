import { useMemo, useState } from 'react';
import DataListCard from '../../../DataListCard/DataListCard';
import ScorePercentage from '../../../../../../../styled-components/StyledScorePercentage';
import { useApolloClient, useMutation } from '@apollo/client';
import { DELETE_VH, DUPLICATE_VH } from './VirtualHumanDataCard.query';
import TypeBadge from '../TypeBadge/TypeBadge';
import { CardMenuItem } from '../../../DataListCard';
import WithConfirmationBox from '../../../../../../../HOCs/WithConfirmationBox';
import useUser from '../../../../helpers/useUser';
import useFlashMessage from '../../../FlashMessage';
import useGetVHCounts from '../../../../../../../components/VirtualHumanCreationWizard/useGetVHCounts';
import PercentTag from '../../../SimulationsList/components/PercentTag';
import { ToDecimalPlace } from '../../../../../../../utility/NumberFormatting';
import VHDuplicationWizard from '../../../../../../../components/VirtualHumanCreationWizard/VHDuplicationWizard/VHDuplicationWizard';
import { Modal } from '@virtidev/toolbox';

export const VirtualHumanDataCard = ({
  virtualHuman,
  confirm,
  setConfirmAsLoading,
  closeConfirm,
  canDuplicate,
  editTags,
  showScore,
  ...props
}) => {
  const {
    ID,
    OrganisationGroups,
    TotalUsers,
    AverageConversationScore,
    AverageDiagnosisScore,
    TotalCompletions,
    Views,
    Type,
    Title,
  } = virtualHuman;

  const { refetch: refetchVHCounts } = useGetVHCounts();

  const { OrganisationID } = useUser();
  const { addFlashMessage } = useFlashMessage();

  const dataPoints = useMemo(() => {
    const percent =
      Views > 0 ? Math.round((TotalCompletions / Views) * 100) : 0;

    const values = [
      { title: 'Users', data: TotalUsers },
      {
        title: 'Attempts',
        data: Views,
      },
      {
        title: 'Completed',
        data: (
          <>
            {TotalCompletions}{' '}
            {!!percent && <PercentTag percent={percent}>{percent}%</PercentTag>}
          </>
        ),
      },
      {
        title: 'Avg. Score',
        data: (
          <>
            {Type === 'medical' && (
              <>
                <ScorePercentage
                  align="center"
                  fallback="-"
                  showNA={!showScore}
                  score={Math.round(AverageDiagnosisScore)}
                />
                {' /'}
              </>
            )}
            <ScorePercentage
              align="center"
              fallback="-"
              showNA={!showScore}
              score={ToDecimalPlace(AverageConversationScore, 1)}
            />
          </>
        ),
      },
    ];
    return values;
  }, [
    showScore,
    TotalUsers,
    AverageConversationScore,
    TotalCompletions,
    Views,
    Type,
    AverageDiagnosisScore,
  ]);

  const client = useApolloClient();
  const [deleteVirtualHuman] = useMutation(DELETE_VH, {
    variables: {
      IDs: [ID],
    },
    onCompleted: () => {
      client.cache.evict({ fieldName: 'readVirtualHumans' });
      addFlashMessage(
        `Virtual Human '${Title || '(Untitled)'}' successfully deleted!`
      );
      refetchVHCounts();
    },
    onError: () => {
      addFlashMessage(
        'Error: The virtual human could not be deleted.',
        'error'
      );
    },
  });

  const [duplicate] = useMutation(DUPLICATE_VH, {
    variables: {
      VirtualHumanID: ID,
      OrganisationID,
      CreationMethod: 'card_duplicate',
    },
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
  });

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const handleDuplicate = () => {
    setDuplicateModalOpen(true);
    // confirm(
    //   async () => {
    //     setConfirmAsLoading(true);
    //     try {
    //       const duped = await duplicate();
    //       if (
    //         !duped?.data?.copyVirtualHuman?.ExternalID ||
    //         duped?.data?.copyVirtualHuman?.ExternalID === null
    //       ) {
    //         addFlashMessage(
    //           'There was an error creating the virtual human - it may be malformed and need deleting.',
    //           'error'
    //         );
    //       } else {
    //         addFlashMessage('Successfully duplicated virtual human');
    //       }
    //       refetchVHCounts();
    //       closeConfirm();
    //     } catch (e) {
    //       addFlashMessage('Failed to duplicate virtual human', 'error');
    //       console.error(e);
    //       setConfirmAsLoading(false);
    //     }
    //   },
    //   'Duplicate Virtual Human',
    //   'Are you sure you wish to duplicate this virtual human?',
    //   null,
    //   null,
    //   true
    // );
  };
  const hideModal = () => setDuplicateModalOpen(false);
  const showModal = () => setDuplicateModalOpen(true);

  return (
    <>
      <DataListCard
        {...props}
        {...virtualHuman}
        ImageMedia={virtualHuman.Thumbnail}
        onDelete={deleteVirtualHuman}
        editLink={`/virtual-humans/${ID}`}
        analyticsLink={`/virtual-humans/${ID}/analytics`}
        itemLink={`/virtual-humans/${ID}`}
        // unlike Simulations query OrganisationGroups were brought in as edges->node
        visibleTo={OrganisationGroups.edges.map((grpEdge) => grpEdge.node)}
        dataPoints={dataPoints}
        cardType="virtual human"
        otherIcons={<TypeBadge type={virtualHuman.Type} />}
        deleteVerificationTextRequired={virtualHuman.Title}
        published={virtualHuman.Status === 'Published'}
        otherMenuOptions={
          <CardMenuItem
            disabled={!canDuplicate}
            iconName="duplicate"
            onClick={handleDuplicate}
          >
            Duplicate
          </CardMenuItem>
        }
        editTags={editTags}
      />
      {duplicateModalOpen && (
        <Modal
          title="Duplicate Virtual Human"
          onHide={hideModal}
          show={showModal}
        >
          <VHDuplicationWizard
            virtualHumanID={virtualHuman.ID}
            virtualHumanExternalID={virtualHuman.ExternalID}
            virtualHumanType={virtualHuman.Type}
            initialTitle={virtualHuman.Title + ' Copy'}
            onCancel={hideModal}
          />
        </Modal>
      )}
    </>
  );
};

export default WithConfirmationBox(VirtualHumanDataCard);
