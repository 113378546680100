import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCustomisedInputHeader,
  StyledLabel,
} from '../../styled-components/StyledCustomisedInputs';
import styled from 'styled-components';
import { useClickOutsideCallback } from '../../utility/EventHooks';
import Details from '../../apps/core/src/components/FeedbackForms/Question/Details/Details';
import Option from '../../apps/core/src/components/FeedbackForms/Question/Option/Option';
import { Button, Icon } from '@virtidev/toolbox';
import { useMutation } from '@apollo/client';
import { CREATE_FEEDBACK_OPTION } from '../../apps/core/src/components/FeedbackForms/Question/Option/Option.query';

export const StyledRadioForm = styled.form`
  display: grid;

  width: 80%;
  margin: auto;
  background-color: var(--primary-bg-color);
  padding: 1rem 2rem;
  border-radius: var(--card-border-radius);
`;

export const StyledRadioFormOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  place-items: start;
  max-width: 20rem;
  padding: 1.5rem 0rem;
  text-align: left;
`;

const StyledOptionsTitle = styled.div`
  margin: 2rem 0rem 1rem 0rem;
  font-weight: 500;
`;

const StyledButtonWrapper = styled.div`
  margin: 1rem 0rem 2rem 0rem;
`;

const StyledPreviewLabel = styled.label`
  word-wrap: break-word;
  width: 15rem;
  max-width: 100%;
  line-height: 1.3rem;
`;

export default function CustomisedInputRadio(props) {
  const [previewChecked, setPreviewChecked] = useState('');
  const questionRef = useRef();

  const [createFeedbackOption, { loading }] = useMutation(
    CREATE_FEEDBACK_OPTION,
    {
      update: (cache) => {
        cache.evict({ fieldName: 'readOneFeedbackForm' });
        cache.evict({ fieldName: 'readOneQuestion' });
      },
      onError: () => {
        props.setLoading(false);
      },
      onCompleted: () => {
        props.setLoading(false);
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleAddNewOption = () => {
    props.setLoading(true);

    // Avoid duplicate values
    let nextValue = props.question.Options.nodes.length.toString();
    const currentOptions = [...props.question.Options.nodes];
    if (currentOptions.length > 0) {
      const lastOptionValue = parseInt(
        currentOptions.sort((a, b) => a.Value - b.Value).at(-1).Value
      );
      nextValue = Math.max(
        isNaN(lastOptionValue) ? 0 : lastOptionValue + 1,
        nextValue
      );
    }

    createFeedbackOption({
      variables: {
        input: {
          QuestionID: props.question.ID,
          Value: nextValue.toString(),
        },
      },
    });
  };

  useClickOutsideCallback(questionRef, () => {
    setTimeout(() => {
      props.setEditing(false);
    }, 100);
  });

  return (
    <>
      {props.editing ? (
        <div>
          <Details
            editing={props.editing}
            register={props.register}
            typeTitle="Radio List Question"
            info="Respondents can select a single option."
            icon="hotspot"
          >
            <StyledOptionsTitle>Options</StyledOptionsTitle>
            {props.question?.Options.nodes.map((option) => {
              return (
                <Option
                  id={`option${option.ID}`}
                  key={`option${option.ID}`}
                  option={option}
                  setLoading={props.setLoading}
                />
              );
            })}
            <StyledButtonWrapper>
              {props.question?.Options.nodes.length < 15 && (
                <Button
                  loading={props.loading || loading}
                  onClick={() => handleAddNewOption()}
                >
                  Add Option
                </Button>
              )}
            </StyledButtonWrapper>
          </Details>
        </div>
      ) : (
        <div>
          <StyledCustomisedInputHeader>
            <Icon icon="hotspot" size="35px" color="#ccc" />
          </StyledCustomisedInputHeader>
          <StyledLabel>{props.question?.Title}</StyledLabel>
          <StyledRadioForm>
            {props.question?.Options.nodes.map((item, index) => {
              return (
                <StyledRadioFormOption key={index + item.Name}>
                  <input
                    type="radio"
                    id={item.Name || 'radio' + index}
                    value={item.index}
                    checked={previewChecked === index}
                    onChange={() => {
                      setPreviewChecked(index);
                    }}
                  />
                  <StyledPreviewLabel>{item.Name}</StyledPreviewLabel>
                </StyledRadioFormOption>
              );
            })}
          </StyledRadioForm>
        </div>
      )}
    </>
  );
}

CustomisedInputRadio.propTypes = {
  question: PropTypes.object,
  removeQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  otherQuestions: PropTypes.array,
  index: PropTypes.number,
};
