import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useMemo } from 'react';

/** @type {(vhId: number[], skip?: boolean) => {vhIdsThatShouldShowScoreAndSessionReport: number[]; loading: boolean;error: any;}} */
const useGetVHsThatShouldShowScoreAndSessionReport = (vhIds, skip) => {
  const { data, loading, error } = useGetVHData(
    `virtual-humans?idOnly=true&shouldShowScoreAndSessionReport=true&ids=${vhIds.join(
      ','
    )}`,
    { skip }
  );

  const vhIdsThatShouldShowScoreAndSessionReport = useMemo(() => {
    if (typeof data?.data?.map !== 'function') return [];
    return data?.data?.map((/** @type {{id: number}} */ vh) => vh.id) ?? [];
  }, [data]);

  return { vhIdsThatShouldShowScoreAndSessionReport, loading, error };
};

export default useGetVHsThatShouldShowScoreAndSessionReport;
