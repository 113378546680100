import { useMutation } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import UploadTUSHandler from '../../../../../../../components/upload/UploadTUSHandler';
import { getResizeImage } from '../../../../helpers/imageResizing/getResizeImage';
import { CREATE_MEDIA } from './MediaUploader.query';
import getConfig from '../../../../../../../lib/config';
import useUser from '../../../../helpers/useUser';

export const MediaUploader = ({
  mediaType,
  src: srcInput,
  onSuccess,
  mediaTusID,
  round,
  onRemove,
  maxFileSize,
  onExistingAsset,
  dragHereText,
  ...props
}) => {
  const [src, setSrc] = useState(srcInput);
  const [remount, setRemount] = useState(2);
  const [tusID, setTusID] = useState(mediaTusID);

  const uploaderRef = useRef();
  const { Organisation } = useUser();

  // move to own query/filesystem
  const [createMedia] = useMutation(CREATE_MEDIA, {
    onCompleted: (data) => {
      onSuccess(data.createMedia, data.createMedia.URL, data.createMedia.TusID);
      setTusID(data.createMedia.TusID);
    },
  });

  useEffect(() => {
    if (mediaType !== 'image') {
      return;
    }

    const resizeParams = round
      ? {
          resize: {
            width: 200,
            height: 200,
          },
        }
      : null;
    const resizedImageURL = getResizeImage(tusID, resizeParams);
    if (resizedImageURL) {
      setSrc(resizedImageURL);
      setRemount((prev) => prev + 1);
    }
  }, [tusID, round, mediaType]);

  // handle the successful upload to TUS
  // creates new Media in silverstripe with a refrence to TUS ID
  const handleSuccess = useCallback(
    async (upload, TusID) => {
      await createMedia({
        variables: {
          Input: {
            URL: `${getConfig('REACT_APP_CLOUDFRONT_URL')}${TusID}`,
            TusID,
            Title: upload.file.name,
            OrganisationID: Organisation.ID,
            Filename: TusID,
            Bucket: getConfig('REACT_APP_S3_BUCKET'),
          },
        },
      });

      setSrc(upload.url);
      setRemount((prev) => prev + 1);
    },
    [createMedia, Organisation]
  );

  const handleRemove = useCallback(() => {
    setRemount(remount + 1);
    setSrc(null);
    setTusID(null);
    onRemove();
    setRemount((prev) => prev + 1);
  }, [onRemove, remount]);

  const typeProps = useMemo(() => {
    if (mediaType === 'image') {
      return {
        imageSrc: src,
        name: 'image-upload',
        dragHereText: dragHereText || 'Upload a new image file',
        onRemoveImg: handleRemove,
        maxFileSize: maxFileSize || 6 * 1024 * 1024,
      };
    }

    if (mediaType === 'audio') {
      return {
        audioSrc: src,
        name: 'audio-upload',
        dragHereText: dragHereText || 'Upload a new audio file',
        maxFileSize: maxFileSize || 100 * 1024 * 1024,
      };
    }

    // video
    return {
      videoSrc: src,
      name: 'video-upload',
      dragHereText: dragHereText || 'Upload a new video file',
      onRemoveAsset: handleRemove,
      maxFileSize: maxFileSize || 3000 * 1024 * 1024,
      onConfirmPrevAsset: onExistingAsset,
      useAssetSelector: !!onExistingAsset,
      useMediaText: 'Select Video from library',
    };
  }, [
    mediaType,
    src,
    dragHereText,
    handleRemove,
    maxFileSize,
    onExistingAsset,
  ]);

  return (
    <UploadTUSHandler
      {...props}
      key={remount}
      ref={uploaderRef}
      width={round ? 200 : 320}
      height={round ? 200 : 180}
      mediaType={mediaType}
      round={round}
      multiple={false}
      loaderSize="default"
      onSuccess={handleSuccess}
      needsOwnNavigationPrompt
      closeAssetSelectorWhenMutationComplete
      {...typeProps}
    />
  );
};

MediaUploader.propTypes = {
  maxFileSize: PropTypes.number,
};

MediaUploader.defaultProps = {
  maxFileSize: null,
};

export default MediaUploader;
