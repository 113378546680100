import FieldContainer from '@core/components/form/FieldContainer';
import ControlledToggleSwitch from '@core/components/form/ToggleSwitch/ControlledToggleSwitch';
import { Modal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const ShareModal = styled(Modal)`
  .modal-box {
    max-width: 700px;
  }
  .modal-content {
    ${({ theme: { spacing } }) => css`
      gap: ${spacing.lg};
      padding-bottom: ${spacing.sm};
    `}
  }
`;

export const RegisterBox = styled(ControlledToggleSwitch)`
  ${({ theme: { color, borderRadius, spacing } }) => css`
    display: flex;
    flex-direction: row;
    border-radius: ${borderRadius.md};
    background: ${color.blue40};
    padding: ${spacing.lg};
  `}
`;

export const SwitchLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
  `}
`;

export const FieldContainerStyled = styled(FieldContainer)`
  margin-bottom: 0;
`;
