export const formFields = {
  roleplayDescription: {
    withTooltipPlaceholder: true,
    helpTitle: 'What type of training scenario do you need?',
    formOptions: { required: true },
    helpText:
      'What is the role play about and what is the goal of the conversation?',
    placeholders: {
      sales:
        'Training sales representatives at a cable company to upsell a current customer.',
      customerService:
        'Training customer service representatives at a bank to handle a customer complaint.',
      hr: 'Training new managers at a warehouse to give a performance review.',
      medicalEmpathy:
        'Training a provider to deliver an unexpected diagnosis to a patient.',
    },
  },
  fileIds: {
    withTooltipPlaceholder: true,
    helpTitle: 'Upload role play brief (optional)',
    formOptions: {},
    helpText:
      'This could be an internal company document or presentation that will help to give the Virtual Human directions and set the scene. Such as, their background, challenges they face, and general personality.',
  },
  scenarioName: {
    withTooltipPlaceholder: false,
    helpTitle: 'Scenario name',
    formOptions: { required: true },
    helpText:
      'This should be short and easily identifiable. Eg “Performance review”',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  learnerBrief: {
    withTooltipPlaceholder: false,
    helpTitle: 'Learner brief',
    formOptions: { required: false },
    helpText:
      'This will be shown to the learner before they start their conversation.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanName: {
    withTooltipPlaceholder: false,
    helpTitle: 'Virtual Human’s name',
    formOptions: { required: false },
    helpText: 'This name will be used during the conversation.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  virtualHumanOpeningLine: {
    withTooltipPlaceholder: false,
    helpTitle: 'Should the Virtual Human start the conversation?',
    formOptions: { required: false },
    helpText:
      'The Virtual Human will say the opening line below if you supply one.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  scenarioDescription: {
    withTooltipPlaceholder: true,
    helpTitle: 'What is the scenario?',
    formOptions: { required: false },
    helpText:
      'Pretend you are giving instructions to an actor. Tell the Virtual Human about their character, their job role, what the situation is, and how they should act.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  conversationExamples: {
    withTooltipPlaceholder: true,
    helpTitle: 'Add example conversation',
    formOptions: { required: false },
    helpText:
      "What would a typical conversation look like? Add a short dialogue between the learner and the Virtual Human. This will help shape the Virtual Human's character and personality. The more examples you give, the more accurate the role play will become.",
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
  evaluations: {
    withTooltipPlaceholder: true,
    helpTitle: 'Evaluations',
    formOptions: { required: false },
    helpText:
      'Set the goals and objectives your learners will be evaluated on during their conversation.',
    placeholders: {
      sales: '',
      customerService: '',
      hr: '',
      medicalEmpathy: '',
    },
  },
};
