import styled, { css } from 'styled-components';

export const Note = styled.div`
  ${({ theme: { font } }) => css`
    ${font.note}
  `}
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.color.error};
`;