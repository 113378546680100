import { gql } from '@apollo/client';

export const DASHBOARD_USAGE = gql`
  query DashboardUsage($datetime: String!, $orgId: ID!) {
    completedSim: readAnalyticsSubmissions(
      filter: {
        Created: { gt: $datetime }
        Finished: { eq: true }
        SimulationID: { ne: 0 }
        OrganisationID: { eq: $orgId }
      }
    ) {
      pageInfo {
        totalCount
      }
    }
    sessionsSim: readAnalyticsSubmissions(
      filter: {
        Created: { gt: $datetime }
        SimulationID: { ne: 0 }
        OrganisationID: { eq: $orgId }
        Member: { Organisations: { ID: { eq: $orgId } } }
      }
    ) {
      pageInfo {
        totalCount
      }
    }
    completedVH: readAnalyticsSubmissions(
      filter: {
        Created: { gt: $datetime }
        Finished: { eq: true }
        VirtualHumanID: { ne: 0 }
        OrganisationID: { eq: $orgId }
      }
    ) {
      pageInfo {
        totalCount
      }
    }
    sessionsVH: readAnalyticsSubmissions(
      filter: {
        Created: { gt: $datetime }
        VirtualHumanID: { ne: 0 }
        OrganisationID: { eq: $orgId }
        # Member: { Organisations: { ID: { eq: $orgId } } }
      }
    ) {
      pageInfo {
        totalCount
      }
    }
    sessions: readAnalyticsSubmissions(
      filter: {
        Created: { gt: $datetime }
        Finished: { eq: true }
        OrganisationID: { eq: $orgId }
        # Member: { Organisations: { ID: { eq: $orgId } } }
      }
      sort: { Created: DESC }
      limit: 10
    ) {
      nodes {
        ID
        Created
        Score
        Member {
          ID
          Name
          UserType
        }
        Simulation {
          ID
          Title
        }
        VirtualHuman {
          ID
          Title
          ExternalID
        }
        event: AnalyticsEvents(filter: { Type: { eq: END_SIM } }, limit: 1) {
          nodes {
            ID
            PDConversationScore
            PDAssessmentID
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
    submissions: readSubmissions(
      filter: {
        Created: { gt: $datetime }
        FeedbackForm: { OrganisationID: { eq: $orgId } }
        # Member: { Organisations: { ID: { eq: $orgId } } }
      }
      sort: { Created: DESC }
      limit: 10
    ) {
      nodes {
        ID
        Created
        FeedbackForm {
          ID
          Title
        }
        Member {
          ID
          Name
          UserType
        }
      }
    }
    signups: readMembers(
      filter: {
        FirstSeen: { gt: $datetime, ne: null }
        Organisations: { ID: { eq: $orgId } }
        Enrolled: { eq: true }
      }
      sort: { FirstSeen: DESC }
      limit: 10
    ) {
      nodes {
        ID
        Name
        Email
        FirstSeen
        SignupMethod
      }
    }
  }
`;
