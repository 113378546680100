import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@virtidev/toolbox';
import VoiceSelector from '../../../../apps/core/src/components/VirtualHumans/VoiceSelector/VoiceSelector';
import { useLocaleOptions } from '../../../../apps/core/src/helpers/useLocaleOptions';
import * as Styled from './VHLocaleSelectionWithDefaultVoice.styled';

/**
 * @typedef {import('@virtidev/toolbox').OptionValue} OptionValue
 */

const VHLocaleSelectionWithDefaultVoice = ({
  onLocaleChange,
  onVoiceChange,
  initialVoiceData,
  initialLocaleCode,
  voiceStringBackup,
  vhType,
  disabled,
  gender,
  label,
}) => {
  const {
    localeOptions,
    localeOptionsLoading,
    localeOptionsError,
    getOptionForLocaleCode,
  } = useLocaleOptions();
  const [selectedLocale, setSelectedLocale] = useState(
    /** @type {OptionValue | null} */ (null)
  );

  const [selectedVoiceData, setSelectedVoiceData] = useState(null);

  const setSelectedLocaleViaCode = useCallback(
    (BCP47Code, resetVoiceData = true) => {
      const option = getOptionForLocaleCode(BCP47Code);
      if (!option) {
        return;
      }
      setSelectedLocale(option);
      onLocaleChange({
        BCP47Code,
      });
      if (resetVoiceData) {
        setSelectedVoiceData(null);
        onVoiceChange(null);
      }
    },
    [getOptionForLocaleCode, onLocaleChange, onVoiceChange]
  );

  const handleVoiceChange = useCallback(
    (voice) => {
      setSelectedVoiceData(voice);
      onVoiceChange(voice);
    },
    [setSelectedVoiceData, onVoiceChange]
  );

  const handleLocaleChange = useCallback(
    (newLocale) => {
      setSelectedLocaleViaCode(newLocale.value);
    },
    [setSelectedLocaleViaCode]
  );

  useEffect(() => {
    setSelectedLocaleViaCode(initialLocaleCode, false);
  }, [initialLocaleCode, setSelectedLocaleViaCode]);

  useEffect(() => {
    setSelectedVoiceData(initialVoiceData);
  }, [initialVoiceData]);

  return (
    <>
      {localeOptionsError && (
        <Styled.ErrorText>Error loading locales</Styled.ErrorText>
      )}
      {!localeOptionsError && (
        <Select
          menuPortalTarget={document.body}
          options={localeOptions}
          value={selectedLocale}
          onChange={handleLocaleChange}
          disabled={disabled}
          loading={localeOptionsLoading}
          label={label}
        />
      )}
      {selectedLocale?.value && (
        <VoiceSelector
          key={selectedLocale?.value} // reset if locale changes to unset value
          disabled={!selectedLocale?.value || disabled}
          filterLocaleCode={selectedLocale?.value}
          onChange={handleVoiceChange}
          voiceID={selectedVoiceData?.ID}
          voiceString={voiceStringBackup}
          vhType={vhType}
          autoVoiceSelection={true}
          gender={gender}
        />
      )}
    </>
  );
};

VHLocaleSelectionWithDefaultVoice.propTypes = {
  onLocaleChange: PropTypes.func.isRequired,
  onVoiceChange: PropTypes.func.isRequired,
  initialVoiceData: PropTypes.object,
  initialLocaleCode: PropTypes.string,
  voiceStringBackup: PropTypes.string,
  vhType: PropTypes.string,
  disabled: PropTypes.bool,
  gender: PropTypes.string,
  label: PropTypes.string,
};

export default VHLocaleSelectionWithDefaultVoice;
