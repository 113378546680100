import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import ReactGA from 'react-ga';
import { inIframe } from '../../../../../utility/WindowUtility';
import getConfig from '../../../../../lib/config';
import { mixpanel, defaultTrackingProps } from '../../helpers/tracker';
import useUser from '../../helpers/useUser';
import {
  hubspotIdentify,
  hubspotTrackPageView,
  initHubspotTracking,
} from '@core/helpers/hubspot';

ReactGA.initialize(getConfig('REACT_APP_GOOGLE_TRACKING_ID'));
initHubspotTracking();

function logPageView(impersonated = false) {
  const path = window.location.pathname + window.location.search;
  if (getConfig('REACT_APP_ENV') === 'production' && !inIframe()) {
    ReactGA.pageview(path);
  }
  if (!inIframe()) {
    if (!impersonated) hubspotTrackPageView(path);
    mixpanel.track_pageview();
  }
}

const AnalyticsService = () => {
  const location = useLocation();
  const user = useUser();
  const prevUserRef = useRef(user);
  const prevUser = prevUserRef.current;

  useEffect(() => {
    if (prevUser !== user) {
      // NOTE: Tracker is tied to prevUser at this point

      // Capture logout events
      if (!user.ID && prevUser.ID) {
        mixpanel.track('user_logged_out');
      }

      // Capture start of user impersonation (impersonator)
      const impersonationStarted =
        user.ImpersonatorID && user.ImpersonatorID === prevUser.ID;
      if (impersonationStarted) {
        mixpanel.track('started_impersonating_user', {
          user_id: user.ID,
        });
      }

      // Capture end of user impersonation (impersonated)
      const impersonationEnded =
        prevUser.ImpersonatorID && prevUser.ImpersonatorID === user.ID;
      if (impersonationEnded) {
        mixpanel.track('ended_being_impersonated');
      }

      if (prevUser.ID && prevUser.ID !== user.ID) {
        // Discard any persisted user data when genuinely switching users, or logging out
        mixpanel.reset();
      }

      // NOTE: Tracker is tied to new user past this point

      // Send current organisation and other constants with all subsequent events
      mixpanel.register(
        {
          ...defaultTrackingProps,
          organisation_id: user.OrganisationID
            ? `${user.OrganisationID}`
            : undefined,
          impersonator_id: user.ImpersonatorID ?? undefined,
        },
        { persistent: false }
      );

      if (user.ID) {
        // Identify the user for all subsequent events
        mixpanel.identify(`${user.ID}`);

        // Save the user's organisations and a non-PII 'name' on their user profile
        const organisationIds = user.Organisations.map((org) => `${org.ID}`);
        mixpanel.people.set({
          organisation_id: organisationIds,
          $name: `${user.ID}`,
          user_type: user.UserType,
        });

        // Don't log impersonated activity to hubspot
        if (!user.ImpersonatorID) hubspotIdentify(user);
      }

      // Capture login events
      if (user.ID && !prevUser.ID) {
        mixpanel.track('user_logged_in');
      }

      // Capture change to organisation
      if (
        user.ID === prevUser.ID &&
        user.OrganisationID &&
        prevUser.OrganisationID &&
        user.OrganisationID !== prevUser.OrganisationID
      ) {
        mixpanel.track('user_changed_organisation', {
          from_organisation_id: prevUser.OrganisationID,
          to_organisation_id: user.OrganisationID,
        });
      }

      // Capture end of user impersonation (impersonator)
      if (impersonationEnded) {
        mixpanel.track('ended_impersonating_user', {
          user_id: prevUser.ID,
        });
      }

      // Capture start of user impersonation (impersonated)
      if (impersonationStarted) {
        mixpanel.track('started_being_impersonated');
      }
    }

    // Store the previous user to compare against the next user in this effect
    prevUserRef.current = user;
  }, [prevUser, user]);

  useEffect(() => {
    // Log a page view when the location changes
    logPageView(!!user.ImpersonatorID);
  }, [location, user.ImpersonatorID]);

  return null;
};

export default AnalyticsService;
