import { Datetime } from '@virtidev/toolbox';
import { useMemo } from 'react';
import StyledLink from '../../../../../../../styled-components/StyledLink';
import { ScoreText, DeletedUserText } from '../DashboardUsagePanel.styled';

export const UsageActivityItem = ({ item }) => {
  const {
    ID,
    Created,
    Score,
    Member: { ID: userId, Name, UserType },
    Simulation,
    VirtualHuman,
    event,
  } = item;

  const [type, id, title, itemId, externalId] = useMemo(() => {
    if (Simulation?.ID !== '0') {
      return ['simulation', Simulation.ID, Simulation.Title, ID];
    }
    if (VirtualHuman?.ID !== '0') {
      return [
        'virtualhuman',
        VirtualHuman.ID,
        VirtualHuman.Title,
        event.nodes[0]?.PDAssessmentID,
        VirtualHuman.ExternalID,
      ];
    }
    return [];
  }, [Simulation, VirtualHuman, event, ID]);

  const userLink = useMemo(() => {
    if (UserType === 'public') {
      return <DeletedUserText>(Public user)</DeletedUserText>;
    }
    if (userId === '0' || !userId) {
      return <DeletedUserText>(Deleted)</DeletedUserText>;
    }
    return (
      <StyledLink to={`/users/${userId}`} title={Name || null}>
        {Name || <DeletedUserText>(No name)</DeletedUserText>}
      </StyledLink>
    );
  }, [UserType, userId, Name]);

  const itemLink = useMemo(() => {
    let target = null;
    if (type === 'virtualhuman') {
      target = {
        pathname: `/virtual-humans/${id}/builder/patient/${externalId}/chat-logs/${itemId}`,
      };
    }
    if (type === 'simulation') {
      target = {
        pathname: `/analytics-data/simulations/${id}`,
        search: `tab=logs&log=${itemId}`,
      };
    }
    if (target) {
      return (
        <StyledLink to={target} title={title || null}>
          {title || <DeletedUserText>(No title)</DeletedUserText>}
        </StyledLink>
      );
    }
    return <DeletedUserText>(Deleted)</DeletedUserText>;
  }, [id, title, type, itemId, externalId]);

  const score = useMemo(() => {
    if (type === 'simulation') {
      if (event?.nodes?.[0]?.ID === '0') {
        return null;
      }
      return Score;
    }
    if (type === 'virtualhuman') {
      return event?.nodes?.[0]?.PDConversationScore;
    }
    return null;
  }, [type, Score, event?.nodes]);

  return (
    <div>
      {userLink} completed {itemLink}
      {score != null && (
        <>
          {' '}
          with a <ScoreText score={score} /> Score
        </>
      )}{' '}
      <Datetime datetime={Created} long difference={24 * 7} />
    </div>
  );
};

export default UsageActivityItem;
