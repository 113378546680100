import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorText,
  FieldError,
  FieldWrapper,
  FormWrapper,
  Text,
  Link,
  LoadingWrapper,
  CreateWithAiModal,
  ModalHeading,
  WelcomeModalSubHeading,
  WelcomeModal,
  ModalFooter,
  WelcomeModalContentSection,
  WelcomeModalImages,
  IntroText,
  ModalFooterText,
  InfoBox,
  ModalFooterActions,
  WelcomeModalHeading,
  TextAreaSmall,
  ReviewText,
} from './CreateVirtualHumanWithAiForm.styled';
import { FormProvider, useForm } from 'react-hook-form';
import { formFields } from './CreateVirtualHumanWithAiForm.config';
import {
  Button,
  ConfirmationModal,
  Icon,
  Select,
  TextInput,
  ToggleSwitch,
} from '@virtidev/toolbox';
import RadioSelect from './components/RadioSelect';
import useGenerateVHV3Details from '../../../../../../utility/VHHooks/useGenerateVHV3Details';
import useGenerateVHEvaluations from '@base/utility/VHHooks/useGenerateVHEvaluations';
import useVirtualHumanDefaults from './hooks/useVirtualHumanDefaults';
import { useMutation } from '@apollo/client';
import { CREATE_VH } from './CreateVirtualHumanWithAiForm.query';
import useUser from '../../../helpers/useUser';
import VHAIFiles from './components/VHAIFiles/VHAIFiles';
import { captureException } from '@sentry/browser';
import tracker from '../../../helpers/tracker';
import { useLocaleOptions } from '../../../helpers/useLocaleOptions';
import { usePrevious } from '../../../../../../utility/CustomHooks';
import LoadingFlavourText from './components/LoadingFlavourText/LoadingFlavourText';
import {
  convItemLoadingTexts,
  generalLoadingTexts,
} from './CreateVirtualHumanWithAiForm.data';
import getConfig from '@base/lib/config';
import { Redirect } from 'react-router-dom';

const bearWithUsText =
  'Note: this process can take a while, please bear with us for a minute or two!';

/**
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHV3Details.types').vhGenerationInput} vhGenerationInput
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHV3Details.types').generatedVhData} generatedVhData
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHV3Details.types').generatedEvaluation} generatedEvaluation
 */

const templateOptions = [
  { value: 'custom', label: 'Custom' },
  { value: 'sales', label: 'Sales' },
  { value: 'customerService', label: 'Customer service' },
  { value: 'hr', label: 'Human resources' },
  { value: 'medicalEmpathy', label: 'Medical' },
];

const VH_CREATION_STEPS = {
  NO_FREE_LICENSES: -3,
  ERROR_EVALUATIONS: -2,
  ERROR_GENERAL: -1,
  WELCOME: 0,
  INPUT: 1,
  GENERATING_GENERAL: 2,
  EDIT_GENERAL: 3,
  GENERATING_EVALUATIONS: 4,
  SAVING: 5,
  DONE: 6,
};

const getMixpanelStepEvent = (step) => {
  switch (step) {
    case VH_CREATION_STEPS.ERROR_GENERAL || VH_CREATION_STEPS.ERROR_EVALUATIONS:
      return 'Error';
    case VH_CREATION_STEPS.WELCOME:
      return 'Welcome';
    case VH_CREATION_STEPS.INPUT:
      return 'Editing Initial Data';
    case VH_CREATION_STEPS.EDIT_GENERAL:
      return 'Editing General Response Data';
    case VH_CREATION_STEPS.DONE:
      return 'Complete';
    default:
      return null;
  }
};

const CreateVirtualHumanWithAiForm = ({ show, onHide, onVHCreated }) => {
  const formMethods = useForm();
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setValue,
  } = formMethods;

  const [
    generateGeneral,
    { isLoading: isGeneratingGeneral, error: errorGeneratingGeneral },
  ] = useGenerateVHV3Details();
  const [
    generateEvaluations,
    { isLoading: isGeneratingEvaluations, error: errorGeneratingEvaluations },
  ] = useGenerateVHEvaluations();

  const [template, setTemplate] = useState('custom');

  const [gender, setGender] = useState('');
  const [locale, setLocale] = useState('en-US');
  const [formStep, setCurrentFormStep] = useState(VH_CREATION_STEPS.WELCOME);
  const [files, setFiles] = useState([]);

  const [editedFields, setEditedFields] = useState([]);

  const setFormStep = useCallback(
    (nextStep) => {
      const mixpanelStepEvent = getMixpanelStepEvent(formStep);
      if (mixpanelStepEvent) {
        tracker.track('vh_create_with_ai_generator_step_completed', {
          step: mixpanelStepEvent,
          ...(editedFields.length > 0 ? { editedFields } : {}),
        });
      }
      setEditedFields([]);
      setCurrentFormStep(nextStep);
    },
    [setCurrentFormStep, formStep, editedFields]
  );

  const [showBackConfirmation, setShowBackConfirmation] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  let warnBeforeClose = false;
  const neverWarnSteps = [
    VH_CREATION_STEPS.DONE,
    VH_CREATION_STEPS.WELCOME,
    VH_CREATION_STEPS.NO_FREE_LICENSES,
  ];

  if (neverWarnSteps.includes(formStep)) {
    warnBeforeClose = false;
  } else if (isDirty || ![VH_CREATION_STEPS.INPUT].includes(formStep)) {
    warnBeforeClose = true;
  }

  const { localeOptions, localeOptionsLoading, localeOptionsError } =
    useLocaleOptions();

  const age = parseInt(watch('virtualHumanAge'), 10) || 35;

  const showGreeting = watch('virtualHumanShowGreeting');

  const {
    environmentID,
    avatarID,
    voiceID,
    vhSourceID,
    loading: vhDefaultsLoading,
    error: vhDefaultsError,
  } = useVirtualHumanDefaults(locale, gender, age);

  const { OrganisationID, Organisation } = useUser();

  const [
    createVH,
    { data: createVHData, loading: createVHLoading, error: createVHError },
  ] = useMutation(CREATE_VH, {
    update: (cache) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
    onCompleted: () => {
      if (onVHCreated) {
        onVHCreated();
      }
    },
  });

  const vhNew = createVHData?.copyVirtualHuman;

  const loading =
    isGeneratingGeneral ||
    isGeneratingEvaluations ||
    createVHLoading ||
    vhDefaultsLoading ||
    localeOptionsLoading;

  const prevGeneralError = usePrevious(errorGeneratingGeneral);
  const prevConvItemsError = usePrevious(errorGeneratingEvaluations);
  const prevCreateVHError = usePrevious(createVHError);

  useEffect(() => {
    if (errorGeneratingGeneral && !prevGeneralError) {
      setFormStep(VH_CREATION_STEPS.ERROR_GENERAL);
    }
  }, [errorGeneratingGeneral, prevGeneralError, formStep, setFormStep]);

  useEffect(() => {
    if (errorGeneratingEvaluations && !prevConvItemsError) {
      setFormStep(VH_CREATION_STEPS.ERROR_EVALUATIONS);
    }
  }, [errorGeneratingEvaluations, prevConvItemsError, formStep, setFormStep]);

  useEffect(() => {
    if (createVHError && !prevCreateVHError) {
      setFormStep(VH_CREATION_STEPS.ERROR_EVALUATIONS);
    }
  }, [createVHError, prevCreateVHError, formStep, setFormStep]);

  const onSubmit = useCallback(
    async (data) => {
      /** @type {vhGenerationInput} */
      const vhData = {
        roleplayDescription: data.roleplayDescription,
        BCP47Code: locale,
        fileIds: data.fileIds,
      };

      switch (formStep) {
        case VH_CREATION_STEPS.ERROR_GENERAL:
        case VH_CREATION_STEPS.INPUT:
          setFormStep(VH_CREATION_STEPS.GENERATING_GENERAL);
          /** @type {{data: {data: generatedVhData}}} */
          const generatedVHResponse = await generateGeneral({ vhData }, true);
          const generatedVH = generatedVHResponse?.data?.data;
          if (generatedVH?.conversationExamples) {
            const formValues = {
              conversationExamples: generatedVH.conversationExamples,
              evaluations: generatedVH.evaluations,
              fileIds: data.fileIds,
              learnerBrief: generatedVH.learnerBrief,
              scenarioDescription: generatedVH.scenarioDescription,
              scenarioName: generatedVH.scenarioName,
              virtualHumanAge: generatedVH.age || 0,
              virtualHumanName: generatedVH.name,
              virtualHumanOpeningLine: generatedVH.openingLine,
              virtualHumanShowGreeting: !!generatedVH.openingLine,
            };
            Object.entries(formValues).forEach(([key, value]) => {
              setValue(key, value);
            });
            setGender(`${generatedVH.gender || ''}`.toLowerCase());
            setFormStep(VH_CREATION_STEPS.EDIT_GENERAL);
          } else {
            console.log('error');
            setFormStep(VH_CREATION_STEPS.ERROR_GENERAL);
            captureException(
              new Error('AI generation of Virtual Human failed')
            );
          }
          break;
        case VH_CREATION_STEPS.ERROR_EVALUATIONS:
        case VH_CREATION_STEPS.EDIT_GENERAL:
          setFormStep(VH_CREATION_STEPS.GENERATING_EVALUATIONS);
          const evaluationsRequestBody = {
            vhData,
            aiGeneratedData: {
              age: data.virtualHumanAge,
              conversationExamples: data.conversationExamples,
              evaluations: data.evaluations,
              fileIds: data.fileIds,
              gender,
              learnerBrief: data.learnerBrief,
              name: data.virtualHumanName,
              openingLine: data.virtualHumanOpeningLine,
              scenarioDescription: data.scenarioDescription,
              scenarioName: data.scenarioName,
            },
          };

          /** @type {{evaluations: generatedEvaluation[]}} */
          const evaluationsResult = (
            await generateEvaluations(evaluationsRequestBody)
          )?.data?.data;
          if (evaluationsResult) {
            setFormStep(VH_CREATION_STEPS.SAVING);
            // Create a new VH
            const newVHData = await createVH({
              variables: {
                VirtualHumanID: vhSourceID,
                OrganisationID,
                VHVoiceID: voiceID,
                EnvironmentID: environmentID,
                AvatarID: avatarID,
                Title: data.scenarioName,
                AdminTitle: null,
                Description: data.learnerBrief,
                Locale: locale,
                CreationMethod: 'ai_create',
                VHDuplicateDataJSON: JSON.stringify({
                  skipGreeting: !data.virtualHumanShowGreeting,
                  aiGeneratedVHV3: {
                    roleplayDescription: data.roleplayDescription,
                    BCP47Code: locale,
                    openingLine: data.virtualHumanOpeningLine,
                    scenarioDescription: data.scenarioDescription,
                    age: data.virtualHumanAge,
                    gender,
                    scenarioName: data.scenarioName,
                    fileIds: data.fileIds,
                    name: data.virtualHumanName,
                    learnerBrief: data.learnerBrief,
                    conversationExamples: data.conversationExamples,
                    evaluations: data.evaluations,
                    evaluationsData: evaluationsResult.evaluations,
                  },
                }),
              },
            });
            tracker.track('vh_create_with_ai_generator_completed');
            if (newVHData?.data?.copyVirtualHuman?.ID) {
              setFormStep(VH_CREATION_STEPS.DONE);
            } else {
              setFormStep(VH_CREATION_STEPS.ERROR_EVALUATIONS);
              captureException(
                new Error('Saving AI generated Virtual Human failed')
              );
            }
          } else {
            setFormStep(VH_CREATION_STEPS.ERROR_EVALUATIONS);
            captureException(
              new Error('AI generation of VH evaluations failed')
            );
          }
          break;
        default:
          break;
      }
    },
    [
      locale,
      formStep,
      setFormStep,
      generateGeneral,
      gender,
      generateEvaluations,
      createVH,
      vhSourceID,
      OrganisationID,
      voiceID,
      environmentID,
      avatarID,
      setValue,
    ]
  );

  const resendGeneral = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit, handleSubmit]);

  const resendItems = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit, handleSubmit]);

  const onBack = useCallback(() => setShowBackConfirmation(true), []);

  const onCancelBack = useCallback(() => {
    setShowBackConfirmation(false);
  }, []);

  const onConfirmBack = useCallback(() => {
    setShowBackConfirmation(false);
    switch (formStep) {
      case VH_CREATION_STEPS.EDIT_GENERAL:
        setFormStep(VH_CREATION_STEPS.INPUT);
        break;
      default:
        break;
    }
  }, [formStep, setFormStep]);

  const onClose = useCallback(
    () => (warnBeforeClose ? setShowCloseConfirmation(true) : onHide()),
    [warnBeforeClose, onHide]
  );

  const onCancelClose = useCallback(() => {
    setShowCloseConfirmation(false);
  }, []);

  const onConfirmClose = useCallback(() => {
    setShowCloseConfirmation(false);
    onHide();
  }, [onHide]);

  const getFieldProps = useCallback(
    (type, id = '') => {
      const { formOptions } = formFields[type];
      const registerId = id || type;
      return {
        ...register(registerId, formOptions),
        placeholder: formFields[type].placeholders?.[template]
          ? `Example: ${formFields[type].placeholders?.[template]}`
          : 'Example: A training for…',
        id: registerId,
        onChange: () => {
          setEditedFields((prevEditedFields) => [
            ...new Set([...prevEditedFields, formFields[type].label || type]),
          ]);
        },
      };
    },
    [register, template]
  );

  const changeFiles = useCallback(
    (newFiles) => {
      console.log(newFiles);
      getFieldProps('fileIds').onChange();
      setFiles(newFiles);
    },
    [getFieldProps]
  );

  function getErrorMessage(fieldError) {
    if (!fieldError) return null;
    const { type, message } = fieldError;
    if (message) return message;
    switch (type) {
      case 'required':
        return 'This field is required';
      default:
        return null;
    }
  }

  function withWrapper(formField, type = '') {
    const name = formField.props.name;
    const { helpTitle, helpText, withTooltipPlaceholder, suffix } = {
      ...formField.props,
      ...(formFields[name] || {}),
      ...(formFields[type] || {}),
    };
    const fieldError = errors[name];
    const errorMessage = getErrorMessage(fieldError);
    return (
      <FieldWrapper key={name}>
        {(helpTitle || helpText) && (
          <InfoBox
            title={
              <>
                {helpTitle}
                {withTooltipPlaceholder && (
                  <>
                    {' '}
                    <Icon
                      icon="help"
                      className={`help-vh-v3-create-with-ai-field-${name}`}
                    />
                  </>
                )}
              </>
            }
            suffix={suffix}
          >
            {helpText}
          </InfoBox>
        )}
        {errorMessage && <FieldError htmlFor={name}>{errorMessage}</FieldError>}
        {formField}
      </FieldWrapper>
    );
  }

  function getModalTitle() {
    switch (formStep) {
      case VH_CREATION_STEPS.ERROR_GENERAL:
        return <ModalHeading>Error</ModalHeading>;
      case VH_CREATION_STEPS.ERROR_EVALUATIONS:
        return <ModalHeading>Error</ModalHeading>;
      case VH_CREATION_STEPS.WELCOME:
        return <></>;
      case VH_CREATION_STEPS.GENERATING_GENERAL:
        return <ModalHeading>Generating scenario details</ModalHeading>;
      case VH_CREATION_STEPS.EDIT_GENERAL:
        return <ModalHeading>Create a Virtual Human with AI</ModalHeading>;
      case VH_CREATION_STEPS.GENERATING_EVALUATIONS:
        return <ModalHeading>Generating evaluations</ModalHeading>;
      case VH_CREATION_STEPS.SAVING:
        return <ModalHeading>Saving your Virtual Human</ModalHeading>;
      case VH_CREATION_STEPS.DONE:
        return <ModalHeading>Virtual Human created</ModalHeading>;
      default:
        return (
          <ModalHeading>
            Create a Virtual Human with AI{' '}
            <Icon icon="help" className="help-vh-v3-create-with-ai-title" />
          </ModalHeading>
        );
    }
  }

  function getModalMaxWidth() {
    switch (formStep) {
      case VH_CREATION_STEPS.WELCOME:
        return '1080px';
      case VH_CREATION_STEPS.INPUT:
        return '800px';
      case VH_CREATION_STEPS.EDIT_GENERAL:
        return '800px';
      default:
        return '500px';
    }
  }

  const goToNextStep = useCallback(() => {
    setFormStep(formStep + 1);
  }, [formStep, setFormStep]);

  function getModalFooter() {
    switch (formStep) {
      case VH_CREATION_STEPS.WELCOME:
        return (
          <ModalFooter>
            <ModalFooterText>
              This feature does not support branching{' '}
              {Organisation?.Medical ? 'or medical freeform ' : ''}
              Virtual Humans.
            </ModalFooterText>
            <Button color="turquoise" onClick={goToNextStep}>
              Get started
            </Button>
          </ModalFooter>
        );
      case VH_CREATION_STEPS.INPUT:
        return (
          <ModalFooter>
            <ModalFooterText>Step 1 of 2</ModalFooterText>
            <Button color="turquoise" onClick={handleSubmit(onSubmit)}>
              Generate scenario
            </Button>
          </ModalFooter>
        );
      case VH_CREATION_STEPS.EDIT_GENERAL:
        return (
          <ModalFooter>
            <ModalFooterText>Step 2 of 2</ModalFooterText>
            <ModalFooterActions>
              <Button color="turquoise" design="transparent" onClick={onBack}>
                Back
              </Button>
              <Button color="turquoise" onClick={handleSubmit(onSubmit)}>
                Create Virtual Human
              </Button>
            </ModalFooterActions>
          </ModalFooter>
        );
      case VH_CREATION_STEPS.GENERATING_EVALUATIONS:
        return null;
      case VH_CREATION_STEPS.GENERATING_GENERAL:
        return null;
      case VH_CREATION_STEPS.SAVING:
        return null;
      default:
        return null;
    }
  }

  return (
    <CreateWithAiModal
      $isWelcomeStep={formStep === VH_CREATION_STEPS.WELCOME}
      $maxWidth={getModalMaxWidth()}
      show={show}
      onHide={onClose}
      title={getModalTitle()}
      footer={getModalFooter()}
    >
      {formStep === VH_CREATION_STEPS.NO_FREE_LICENSES && (
        <Text>
          Sorry, but this feature can only be used for freeform Virtual Humans,
          and all of your freeform licenses are in use. If you require more
          licenses, please contact{' '}
          <Link href="mailto:support@virti.com">support@virti.com</Link>.
        </Text>
      )}
      {formStep === VH_CREATION_STEPS.ERROR_GENERAL && (
        <ErrorText>
          <div>Sorry, something went wrong.</div>
          <Button color="turquoise" onClick={resendGeneral}>
            Try again
          </Button>
        </ErrorText>
      )}
      {formStep === VH_CREATION_STEPS.ERROR_EVALUATIONS && (
        <ErrorText>
          <div>Sorry, something went wrong.</div>
          <Button color="turquoise" onClick={resendItems}>
            Try again
          </Button>
        </ErrorText>
      )}
      {formStep === VH_CREATION_STEPS.WELCOME && (
        <WelcomeModal>
          <WelcomeModalContentSection>
            <div>
              <WelcomeModalHeading>Create with AI ✨</WelcomeModalHeading>
              {getConfig('REACT_APP_ENV') === 'dev' && (
                <div style={{ color: 'red' }}>
                  WARNING: COMPLETING CREATION MAY CREATE A PRODUCTION VH
                </div>
              )}
              <IntroText>
                Get started with Virtual Human the fast way, using our new AI
                tool. Give us the context and goals and we'll create a character
                to get you up and running quickly.
              </IntroText>
            </div>
            <div>
              <WelcomeModalSubHeading>Get inspired</WelcomeModalSubHeading>
              <Text>
                Get helpful content suggestions based on common workplace
                scenarios. Speeding up your workflow and improving training
                effectiveness.
              </Text>
            </div>
            <div>
              <WelcomeModalSubHeading>Set your goals</WelcomeModalSubHeading>
              <Text>
                Give a summary of the context and goals of your scenario, and
                we'll suggest the learning objectives and conversation items to
                help you reach it.
              </Text>
            </div>
            <div>
              <WelcomeModalSubHeading>Customize</WelcomeModalSubHeading>
              <Text>
                Describe your Virtual Human&apos;s personality and interests to
                give them natural and authentic responses.
              </Text>
            </div>
          </WelcomeModalContentSection>
          <WelcomeModalImages>
            <img src="/images/vh/create_with_ai.png" alt="" />
          </WelcomeModalImages>
        </WelcomeModal>
      )}
      {formStep === VH_CREATION_STEPS.INPUT && (
        <FormWrapper>
          <FormProvider {...formMethods}>
            {withWrapper(
              <RadioSelect
                helpTitle="Pick a role play example"
                legend="Pick a role play example"
                name="placeholderTemplate"
                id="placeholderTemplate"
                helpText="We’ve created some common scenarios that you can pick from, which you can edit. Or go at it alone with our custom option."
                options={templateOptions}
                value={template}
                onChange={(e) => {
                  const type = e.target.value;
                  const placeholder =
                    formFields.roleplayDescription.placeholders[type] || '';
                  setValue('roleplayDescription', placeholder);
                  setTemplate(type);
                }}
              />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              {localeOptionsError && <div>Error loading locales</div>}
              {!localeOptionsError &&
                withWrapper(
                  <Select
                    label="Language"
                    options={localeOptions}
                    loading={localeOptionsLoading}
                    value={locale}
                    onChange={(option) => {
                      setLocale(option.value);
                    }}
                  />
                )}
              {withWrapper(
                <TextInput
                  multiline
                  label="Role play description"
                  {...getFieldProps('roleplayDescription')}
                />
              )}
              {withWrapper(
                <VHAIFiles
                  onChange={changeFiles}
                  name="fileIds"
                  files={files}
                />
              )}
            </form>
          </FormProvider>
        </FormWrapper>
      )}
      {formStep === VH_CREATION_STEPS.GENERATING_GENERAL && loading && (
        <LoadingWrapper>
          <Text>{bearWithUsText}</Text>
          <LoadingFlavourText flavourTexts={generalLoadingTexts} />
        </LoadingWrapper>
      )}
      {formStep === VH_CREATION_STEPS.EDIT_GENERAL && (
        <>
          <ReviewText>Review your details</ReviewText>
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              {withWrapper(
                <TextInput
                  label="Scenario name"
                  {...getFieldProps('scenarioName')}
                />
              )}
              {withWrapper(
                <TextInput
                  label="Brief"
                  multiline
                  {...getFieldProps('learnerBrief')}
                />
              )}
              {withWrapper(
                <TextInput
                  label="Name"
                  {...getFieldProps('virtualHumanName')}
                />
              )}
              {withWrapper(
                <TextAreaSmall
                  label="Opening line"
                  multiline
                  description="How would the Virtual Human greet the learner?"
                  suffix={
                    <ToggleSwitch
                      onChange={(event) => {
                        setValue(
                          'virtualHumanShowGreeting',
                          event.target.checked
                        );
                      }}
                      checked={showGreeting}
                    />
                  }
                  disabled={!showGreeting}
                  {...getFieldProps('virtualHumanOpeningLine')}
                />
              )}
              {withWrapper(
                <TextInput
                  label="Virtual Human brief"
                  multiline
                  {...getFieldProps('scenarioDescription')}
                />
              )}
              {withWrapper(
                <TextInput
                  label="Examples"
                  multiline
                  {...getFieldProps('conversationExamples')}
                />
              )}
              {withWrapper(
                <TextAreaSmall
                  label="Evaluations"
                  multiline
                  {...getFieldProps('evaluations')}
                />
              )}
            </form>
          </FormWrapper>
        </>
      )}
      {formStep === VH_CREATION_STEPS.GENERATING_EVALUATIONS && loading && (
        <LoadingWrapper>
          <Text>{bearWithUsText}</Text>
          <LoadingFlavourText flavourTexts={convItemLoadingTexts} />
        </LoadingWrapper>
      )}
      {formStep === VH_CREATION_STEPS.SAVING && loading && (
        <LoadingWrapper>
          <Text>{bearWithUsText}</Text>
          <LoadingFlavourText
            flavourTexts={[{ min: 0, max: 99999, text: 'Saving result' }]}
          />
        </LoadingWrapper>
      )}
      {formStep === VH_CREATION_STEPS.DONE && vhNew && (
        <Redirect to={`/virtual-humans/${vhNew.ID}`} />
      )}
      <ConfirmationModal
        customTitle="Please confirm"
        onHide={onCancelBack}
        onConfirm={onConfirmBack}
        show={showBackConfirmation}
      >
        If you go back, all generated content and any edits you made to it at
        this step will be discarded. You'll be able to pick up where you left
        off at the previous step.
      </ConfirmationModal>
      <ConfirmationModal
        customTitle="Please confirm"
        onHide={onCancelClose}
        onConfirm={onConfirmClose}
        show={showCloseConfirmation}
      >
        Your edits will be discarded and your Virtual Human will not be saved.
      </ConfirmationModal>
    </CreateWithAiModal>
  );
};

CreateVirtualHumanWithAiForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onVHCreated: PropTypes.func,
};

export default CreateVirtualHumanWithAiForm;
