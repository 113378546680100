import { gql } from '@apollo/client';
import { pseudoCourseFragment, pseudoModuleFragment } from './CourseQueries';
import { pseudoSimulationFragmentBase } from './SimulationQueries';

export const READ_LEARNER_COURSES = gql`
  query readLearnerCourses($OrganisationID: ID!, $offset: Int, $limit: Int) {
    readCourses(
      filter: {
        Organisation: { ID: { eq: $OrganisationID } }
        Status: { eq: "Published" }
      }
      offset: $offset
      limit: $limit
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          LastEdited
          Likes
          Views
          isLiked
          Title
          Status
          Popularity
          Description
          MemberProgress
          ImageMedia {
            URL
          }
        }
      }
    }
  }
`;

export const READ_LEARNER_COURSE = gql`
  query readLearnerCourse($ID: ID!) {
    readOneCourse(filter: { ID: { eq: $ID } }) {
      ${pseudoCourseFragment}
      Modules {
        nodes {
          ${pseudoModuleFragment}
        }
      }
      FeedbackFormBeforeCompleted
      FeedbackFormBefore {
        ID
        Title
        Hash
        AllowMultipleSubmissions
      }
      FeedbackFormAfterCompleted
      FeedbackFormAfter {
        ID
        Title
        Hash
        AllowMultipleSubmissions
      }
      Resources {
        edges {
          node {
            ID
            Title
            Media {
              ID
              URL
              Title
            }
          }
        }
      }
      OrganisationGroups{
        edges {
          node {
            ID
          }
        }
      }
    }
  }
`;

export const READ_STUDENT_SIMULATION = gql`
  query readStudentSimulation($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ${pseudoSimulationFragmentBase}
      MyBadges {
        nodes {
          Label
          Created
        }
      }
      OverviewPages {
        edges {
          node {
            ID
            Title
            Content
            SortOrder
            SimulationID
            Media {
              ID
              Title
              URL
              Width
              Height
              Length
              TusID
            }
          }
        }
      }
    }
  }
`;

export const READ_UNIT_MODULE = gql`
  query readOneUnit($ID: ID!) {
    readOneUnit(filter: { ID: { eq: $ID } }) {
      ID
      isCompleted
      Module {
        ID
        CourseID
        Units {
          edges {
            node {
              ID
              Type
              Simulation {
                ID
                Title
                Description
                ImageMedia {
                  URL
                }
              }
              VirtualHuman {
                ID
                Title
                Description
                ThumbnailURL
              }
            }
          }
        }
      }
    }
  }
`;

export const READ_SIMULATION_FOR_UNITY = gql`
  query readOneSimulationForUnity($filter: SimulationFilterFields) {
    readOneSimulation(filter: $filter) {
      ID
      Likes
      isLiked
      OrganisationID
      Organisation {
        ID
        Name
        CustomerStage
      }
      ShowRegister
      ShareToken
      ContentEndpoint
      TranscodingStatus
      FirstVideo: Videos(limit: 1) {
        nodes {
          PosterURL
        }
      }
      FeedbackFormBeforeCompleted
      FeedbackFormAfterCompleted
      FeedbackFormBefore {
        ID
        Title
        Type
        AllowMultipleSubmissions
      }
      FeedbackFormAfter {
        ID
        Title
        Type
        AllowMultipleSubmissions
      }
      OverviewPages {
        edges {
          node {
            ID
            Title
            Content
            SortOrder
            SimulationID
            Media {
              ID
              Title
              URL
              Width
              Height
              Length
              TusID
            }
          }
        }
      }
      DownloadVersion
      Views
      Created
      Title
      Description
      Status
      Stereoscopic
      Content360
      ImageMedia {
        ID
        URL
        TusID
      }
      TotalUsers
      Views
      StartVideoID
      MyBadges {
        edges {
          node {
            ID
            Created
            Label
            Title
          }
        }
      }
      Videos(sort: { SortOrder: ASC }) {
        nodes {
          ID
          Title
          Rotation
          VideoURL
          Content360
          VideoMedia {
            ID
            VideoDownloadLowURL
            VideoDownloadURL
            VideoDownloadHighURL
            VideoURL
            PosterURL
            TusID
            URL
          }
          EndAction
          VideoRedirectID
          VideoRedirectEventID
          Length
          Subtitles {
            nodes {
              ID
              Title
              Language
              Type
              URL
            }
          }
          Events(filter: { Simulation: $filter }) {
            nodes {
              ID
              IconType
              IconMediaID
              IconMedia {
                ID
                Filename
                TusID
                URL
              }
              Title
              SimulationID
              HotspotScale
              VideoID
              AudioMedia {
                ID
                URL
              }
              Type
              Action
              Description
              Content
              Label
              ShowLabel
              ShowOverlay
              Timestamp
              BookmarkVisible
              VideoTimestamp
              RedirectVideoID
              RedirectEventID
              AnswersOrdered
              Duration
              Countdown
              PauseVideo
              PosX
              PosY
              Opacity
              Size
              EmbedVideoMediaID
              EmbedVideoMedia {
                ID
                VideoURL
                VideoDownloadLowURL
                VideoDownloadHighURL
                VideoDownloadURL
              }
              ImageMedia {
                ID
                URL
                TusID
              }
              Frameless
              Sticky
              QuestionType
              Options {
                nodes {
                  ID
                  Text
                  Description
                  Feedback
                  Correct
                  Action
                  RedirectVideoID
                  RedirectEventID
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ANALYTICS_EVENT = gql`
  mutation CreateAnalyticsEvent($Input: CreateAnalyticsEventInput!) {
    createAnalyticsEvent(input: $Input) {
      ID
    }
  }
`;

export const READ_LEARNER_BADGES = gql`
  query readLearnerBadges($ID: ID!) {
    readMemberBadges(filter: { Member: { ID: { eq: $ID } } }, limit: 6) {
      nodes {
        ID
        Label
        Created
        Title
        Simulation {
          Title
        }
      }
    }
  }
`;
