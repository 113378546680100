import { useMemo } from 'react';
import useGetVHsWithEvaluationsOrObjectives from './useGetVHsWithEvaluationsOrObjectives';

/**
 * @type {(vhId: number) => {hasEvaluations: boolean; loading: boolean; error: any;}}
 */
const useGetVHHasEvaluations = (vhId) => {
  const { vhIdsWithEvaluationsOrObjectives, loading, error } =
    useGetVHsWithEvaluationsOrObjectives([vhId], !vhId);
  const hasEvaluations = useMemo(() => {
    return (
      vhIdsWithEvaluationsOrObjectives &&
      vhIdsWithEvaluationsOrObjectives.includes(vhId)
    );
  }, [vhIdsWithEvaluationsOrObjectives, vhId]);
  return { hasEvaluations, loading, error };
};

export default useGetVHHasEvaluations;
