import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlashMessage } from '../../../../FlashMessage';
import { CREATE_AVATAR, READ_AVATARS } from './CxAvatarsEditor.query';
import * as StyledCxVH from '../../CxVirtualHuman.styled';
import SelectedAvatarEditor from './components/SelectedAvatarEditor/SelectedAvatarEditor';

const CxAvatarsEditor = () => {
  const { data: avatars } = useQuery(READ_AVATARS);
  const [createAvatar, { loading: creatingAvatar }] =
    useMutation(CREATE_AVATAR);
  const [selectedAvatarID, setSelectiveAvatarId] = useState(null);
  const selectedAvatar = useMemo(
    () =>
      avatars?.readAvatars?.nodes &&
      avatars.readAvatars.nodes.find(
        (avatar) => avatar.ID === selectedAvatarID
      ),
    [avatars, selectedAvatarID]
  );
  const { addFlashMessage } = useFlashMessage();

  const handleCreateAvatar = useCallback(() => {
    createAvatar({
      variables: {
        input: {
          Name: 'Unnamed',
          DisplayName: 'Unnamed',
          Age: '30',
          Gender: 'male',
          IsPreview: true,
        },
      },
      refetchQueries: [{ query: READ_AVATARS }],
      onCompleted: () => {
        addFlashMessage('Created avatar');
      },
      onError: () => {
        addFlashMessage('Failed to create avatar', 'error');
      },
    });
  }, [createAvatar, addFlashMessage]);

  return (
    <StyledCxVH.Wrapper>
      <h1>Avatars</h1>
      <StyledCxVH.ContentWrapper>
        <StyledCxVH.ListWrapper>
          {avatars?.readAvatars?.nodes &&
            avatars.readAvatars.nodes.map((avatar) => (
              <StyledCxVH.EditorListItem
                key={avatar.ID}
                active={selectedAvatarID === avatar.ID}
                onClick={() => setSelectiveAvatarId(avatar.ID)}
              >
                {avatar.DisplayName} ({avatar.Name})
              </StyledCxVH.EditorListItem>
            ))}
          <Button loading={creatingAvatar} onClick={handleCreateAvatar}>
            Create Blank Avatar
          </Button>
        </StyledCxVH.ListWrapper>
        <StyledCxVH.SelectedItem>
          {selectedAvatar && (
            <SelectedAvatarEditor selectedAvatar={selectedAvatar} />
          )}
        </StyledCxVH.SelectedItem>
      </StyledCxVH.ContentWrapper>
    </StyledCxVH.Wrapper>
  );
};

export default CxAvatarsEditor;
