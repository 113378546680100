import FieldContainer from '@core/components/form/FieldContainer';
import { Modal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Description = styled.span`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
  `}
`;

export const ShareModal = styled(Modal)`
  .modal-box {
    max-width: 700px;
  }
  .modal-content {
    ${({ theme: { spacing } }) => css`
      gap: ${spacing.lg};
      padding-bottom: ${spacing.sm};
    `}
  }
`;

export const FieldContainerStyled = styled(FieldContainer)`
  margin-bottom: 0;
`;
