import styled, { css } from 'styled-components';

const Badge = styled.span`
  ${({ theme: { color, spacing } }) => css`
    padding: ${spacing.xs} ${spacing.sm};
    border-radius: 8px;
    color: ${color.midnight};

    /* Small Note sm */
    font-family: Cabin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const BadgeTeal = styled(Badge)`
  ${({ theme: { color } }) => css`
    background: ${color.turquoise10};
  `}
`;

export const BadgePink = styled(Badge)`
  ${({ theme: { color } }) => css`
    background: ${color.pink10};
  `}
`;

export const BadgeBlue = styled(Badge)`
  ${({ theme: { color } }) => css`
    background: ${color.blue10};
  `}
`;
