import mixpanel from 'mixpanel-browser';
import getConfig from '../../../../lib/config';
import { Userpilot } from 'userpilot';

export const defaultTrackingProps = {
  channel: 'web_app',
  web_app_env: getConfig('REACT_APP_ENV'),
};

export { mixpanel };

mixpanel.init(getConfig('REACT_APP_MIXPANEL_PROJECT_TOKEN'), {
  debug: !!getConfig('REACT_APP_MIXPANEL_DEBUG'),
  api_host: 'https://api-eu.mixpanel.com',
});

// Prevent super properties from spanning subdomains
mixpanel.unregister('channel');
mixpanel.unregister('web_app_env');
mixpanel.unregister('organisation_id');
mixpanel.unregister('impersonator_id');
mixpanel.register(defaultTrackingProps, { persistent: false });

export const trackingHeaders = () => {
  const props = {
    channel: 'web_app',
    distinct_id: mixpanel.get_distinct_id(),
  };
  return {
    'X-Virti-Mixpanel': JSON.stringify(props),
  };
};

export const tracker = {
  /**
   * @param {string} name
   * @param {object} data
   */
  track: (name, data = {}) => {
    mixpanel.track(name, data);
    Userpilot.track(name, data);
  },
};

export default tracker;
