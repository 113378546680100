import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useMemo } from 'react';

/**
 * @typedef {import('@virtidev/toolbox').OptionValue} OptionValue
 */

export const supportedLanguages = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: 'Chinese (Taiwan)',
    value: 'zh-TW',
  },
  {
    label: 'Chinese (China)',
    value: 'zh-CN',
  },
  {
    label: 'Czech',
    value: 'cs-CZ',
  },
  {
    label: 'Dutch',
    value: 'nl-NL',
  },
  {
    label: 'Spanish',
    value: 'es-ES',
  },
  {
    label: 'French',
    value: 'fr-FR',
  },
  {
    label: 'German',
    value: 'de-DE',
  },
  {
    label: 'Italian',
    value: 'it-IT',
  },
  {
    label: 'Japanese',
    value: 'ja-JP',
  },
  {
    label: 'Korean',
    value: 'ko-KR',
  },
  {
    label: 'Portuguese',
    value: 'pt-PT',
  },
  {
    label: 'Russian',
    value: 'ru-RU',
  },
  {
    label: 'Danish',
    value: 'da-DK',
  },
  {
    label: 'Finnish',
    value: 'fi-FI',
  },
  {
    label: 'Norwegian Bokmål',
    value: 'nb-NO',
  },
  {
    label: 'Polish',
    value: 'pl-PL',
  },
  {
    label: 'Swedish',
    value: 'sv-SE',
  },
  {
    label: 'Turkish',
    value: 'tr-TR',
  },
  {
    label: 'Ukrainian',
    value: 'uk-UA',
  },
];

export const useLocaleOptions = function () {
  const { data, loading, error } = useGetVHData(`languages`);

  const result = useMemo(() => {
    const languages = (data?.data ?? []).sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    /** @type {OptionValue[]} */
    const localeOptions = supportedLanguages.filter((localeOption) =>
      languages.some((lang) => lang.BCP47Code === localeOption.value)
    );

    const getLanguageForLocale = (localeCode) => {
      return languages.find((lang) => lang.BCP47Code === localeCode);
    };

    const getOptionForLocaleCode = (localeCode) => {
      return localeOptions.find((option) => option.value === localeCode);
    };

    return {
      localeOptions,
      localeOptionsLoading: loading,
      localeOptionsError: error,
      languages,
      getLanguageForLocale,
      getOptionForLocaleCode,
    };
  }, [data?.data, error, loading]);

  return result;
};
