import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './VHLocaleVoiceSelection.styled';
import { HelpLabel, Select } from '@virtidev/toolbox';
import VoiceSelector from '../../../../apps/core/src/components/VirtualHumans/VoiceSelector/VoiceSelector';
import { useLocaleOptions } from '../../../../apps/core/src/helpers/useLocaleOptions';

/**
 * @typedef {import('@virtidev/toolbox').OptionValue} OptionValue
 */

const VHLocaleVoiceSelection = ({
  onLocaleChange,
  onVoiceChange,
  initialVoiceData,
  initialLocaleCode,
  voiceStringBackup,
  vhType,
}) => {
  const [selectedLocaleCode, setSelectedLocaleCode] = useState(
    /** @type {OptionValue | null} */ (null)
  );
  const {
    localeOptions,
    localeOptionsLoading,
    localeOptionsError,
    getOptionForLocaleCode,
  } = useLocaleOptions();

  const [selectedVoiceData, setSelectedVoiceData] = useState(null);
  const [selectedVoiceStringAsBackup, setSelectedVoiceStringAsBackup] =
    useState(null);

  const setSelectedLocaleViaCode = useCallback(
    (BCP47Code, resetVoiceData = true) => {
      const option = getOptionForLocaleCode(BCP47Code);
      if (!option) {
        return;
      }
      setSelectedLocaleCode(option);
      onLocaleChange(BCP47Code);
      if (resetVoiceData) {
        setSelectedVoiceData(null);
        onVoiceChange(null);
      }
    },
    [getOptionForLocaleCode, onLocaleChange, onVoiceChange]
  );

  const handleVoiceChange = useCallback(
    (voice) => {
      setSelectedVoiceData(voice);
      onVoiceChange(voice);
    },
    [setSelectedVoiceData, onVoiceChange]
  );

  const handleLocaleChange = useCallback(
    (newLocale) => {
      setSelectedLocaleViaCode(newLocale.value);
    },
    [setSelectedLocaleViaCode]
  );

  useEffect(() => {
    setSelectedLocaleViaCode(initialLocaleCode, false);
  }, [initialLocaleCode, setSelectedLocaleViaCode]);

  useEffect(() => {
    setSelectedVoiceData(initialVoiceData);
  }, [initialVoiceData]);

  useEffect(() => {
    setSelectedVoiceStringAsBackup(voiceStringBackup);
  }, [voiceStringBackup]);

  return (
    <>
      {localeOptionsError && (
        <Styled.ErrorText>Error loading locales</Styled.ErrorText>
      )}
      {!localeOptionsError && (
        <Styled.DropdownWrapper>
          <HelpLabel htmlFor="voice-select">Language</HelpLabel>
          <Select
            menuPortalTarget={document.body}
            options={localeOptions}
            value={selectedLocaleCode}
            onChange={handleLocaleChange}
            loading={localeOptionsLoading}
          />
        </Styled.DropdownWrapper>
      )}
      {selectedLocaleCode?.value && (
        <Styled.DropdownWrapper>
          <HelpLabel htmlFor="voice-select">Voice</HelpLabel>
          <Styled.VoiceSelectorWrapper>
            <VoiceSelector
              key={selectedLocaleCode?.value} // reset if locale changes to unset value
              disabled={!selectedLocaleCode?.value}
              filterLocaleCode={selectedLocaleCode?.value}
              onChange={handleVoiceChange}
              voiceID={selectedVoiceData?.ID}
              voiceString={voiceStringBackup}
              vhType={vhType}
            />
          </Styled.VoiceSelectorWrapper>
        </Styled.DropdownWrapper>
      )}
    </>
  );
};

VHLocaleVoiceSelection.propTypes = {
  onLocaleChange: PropTypes.func.isRequired,
  onVoiceChange: PropTypes.func.isRequired,
  initialVoiceData: PropTypes.object,
  initialLocaleCode: PropTypes.string,
  voiceStringBackup: PropTypes.string,
};

export default VHLocaleVoiceSelection;
