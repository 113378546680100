import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import VHAvailabilityCounterPopulated from '../VHAvailabilityCounterPopulated/VHAvailabilityCounterPopulated';
import { useMutation, useQuery } from '@apollo/client';
import useFlashMessage from '../../../apps/core/src/components/FlashMessage';
import useUser from '../../../apps/core/src/helpers/useUser';
import WithConfirmationBox from '../../../HOCs/WithConfirmationBox';
import { CREATE_VH } from '../VHCreationWizard.query';
import * as Styled from './VHDuplicationWizard.styled';
import VHLocaleVoiceSelection from './VHLocaleVoiceSelection/VHLocaleVoiceSelection';
import { Button, HelpLabel } from '@virtidev/toolbox';
import { READ_VIRTUAL_HUMAN_VOICE } from './VHDuplicationWizard.query';
import { usePrevious } from '../../../utility/CustomHooks';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';

const VHDuplicationWizard = ({
  onVHCreated,
  initialTitle,
  virtualHumanID,
  virtualHumanExternalID,
  virtualHumanType,
  onCancel,
}) => {
  const { OrganisationID } = useUser();
  const [nameValue, setNameValue] = useState(initialTitle);
  const [voiceData, setVoiceData] = useState(null);
  const [voiceString, setVoiceString] = useState(null);
  const [localeCode, setLocaleCode] = useState('');

  const {
    data: vhData,
    loading: vhLoading,
    error: vhError,
  } = useGetVHData(`virtual-humans/${virtualHumanExternalID}`, {
    skip: !virtualHumanExternalID,
  });

  const { addFlashMessage } = useFlashMessage();

  const { data } = useQuery(READ_VIRTUAL_HUMAN_VOICE, {
    variables: { ID: virtualHumanID },
  });

  useEffect(() => {
    if (vhData?.data && !localeCode) {
      setLocaleCode(vhData?.data?.lang?.BCP47Code || 'en-US');
    }
  }, [localeCode, vhData]);

  useEffect(() => {
    if (data?.readOneVirtualHuman?.VHVoice) {
      setVoiceData(data.readOneVirtualHuman.VHVoice);
      setVoiceString(data.readOneVirtualHuman.Voice);
    }
  }, [data]);

  const [createVH, { loading: creating }] = useMutation(CREATE_VH, {
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
    onCompleted: () => {
      addFlashMessage('Successfully duplicated Virtual Human');
      if (onVHCreated) {
        onVHCreated();
      }
    },
    onError: () => {
      addFlashMessage('Error retrieving vh data', 'error');
    },
  });

  const handleCreateVirtualHuman = () => {
    createVH({
      variables: {
        VirtualHumanID: virtualHumanID,
        OrganisationID,
        Title: nameValue,
        Locale: localeCode,
        VHVoiceID: voiceData?.ID,
        CreationMethod: 'wizard_duplicate',
      },
    });
  };

  const handleVoiceChange = useCallback(
    (voiceData) => {
      if (voiceData) {
        setVoiceData(voiceData);
      }
    },
    [setVoiceData]
  );

  const handleLocaleChange = useCallback(
    (localeData) => {
      setLocaleCode(localeData);
    },
    [setLocaleCode]
  );

  // if locale changes from one to another (rather than from null to any) then reset voice
  // this is so that it doesn't store old voice data when switching locales, which leads to
  // a useable Duplicate button but with an empty voice select
  const prevLocaleCode = usePrevious(localeCode);
  useEffect(() => {
    if (prevLocaleCode && localeCode && prevLocaleCode !== localeCode) {
      setVoiceData(null);
      setVoiceString(null);
    }
  }, [localeCode, prevLocaleCode]);

  return (
    <Styled.ModalLayout>
      {virtualHumanType === 'freeform' && (
        <Styled.ModalTopArea>
          <VHAvailabilityCounterPopulated />
        </Styled.ModalTopArea>
      )}
      <Styled.ModalContentArea>
        <Styled.ContentSectionWrapper>
          <Styled.DuplicateNameWrapper>
            <HelpLabel htmlFor="duplicate-vh-name">
              New Virtual Human Name
            </HelpLabel>
            <Styled.TextInputField
              type="text"
              id="duplicate-vh-name"
              onChange={(e) => setNameValue(e.target.value)}
              placeholder="Enter a name"
              value={nameValue}
            />
          </Styled.DuplicateNameWrapper>
          <VHLocaleVoiceSelection
            onLocaleChange={handleLocaleChange}
            onVoiceChange={handleVoiceChange}
            initialLocaleCode={localeCode}
            initialVoiceData={voiceData}
            voiceStringBackup={voiceString}
            vhType={data?.readOneVirtualHuman?.Type}
          />
          <Styled.ButtonsWrapper>
            <Button disabled={creating} onClick={onCancel} color="transparent">
              Cancel
            </Button>
            <Button
              disabled={!voiceData || !localeCode || !nameValue || creating}
              loading={creating}
              onClick={handleCreateVirtualHuman}
            >
              Duplicate
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.ContentSectionWrapper>
      </Styled.ModalContentArea>
    </Styled.ModalLayout>
  );
};

VHDuplicationWizard.propTypes = {
  onVHCreated: PropTypes.func.isRequired,
  initialTitle: PropTypes.string,
  virtualHumanID: PropTypes.string.isRequired,
  virtualHumanExternalID: PropTypes.number.isRequired,
  virtualHumanType: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};

export default WithConfirmationBox(VHDuplicationWizard);
